import React, { useState, useEffect } from 'react';
import './App.css';
import animate, { spin, spinBack  } from './animate.js'
// import sample from './sampleWorks.js'
// import WorkMenuPage from './works/WorkMenuPage'
import 'react-awesome-slider/dist/styles.css';

const App = () => {
  const [loaded, updateLoaded] = useState(0)

  useEffect(() => {
    if (loaded < 3) {
      updateLoaded(loaded + 1)
    }
  })
  useEffect(() => {
    if (loaded === 3) {
      animate()
      setTimeout(() => {
        spinBack()
      }, 1500)
    }
  }, [loaded])

  return (
    <div className="App">
      <div className="Left">
        <div className='content'>
          <div className="main">
            welcome,
              <br />
            <br />
                my name is carlos santos
                <br />
            <br />
                  i’m a creative technologist
                  <br />
            <br />
                  currently i'm working on
                  <br />
            <br />
            <span id="studios" onClick={() => { spin() }}>los studios</span>
            <br />
            <br />
             i can be contacted at
             <br />
            <br />
             los -at- losstudios -dot- xyz
             <br />
            <br />

             or
             <br />
            <br />

             on my <span 
             id='here' 
             onTouchEnd={()=>{window.location.href = 'https://www.instagram.com/losllc/';}}
             onClick={()=>{window.location.href = 'https://www.instagram.com/losllc/';}}
             >
                instagram 
            </span>
          </div>
        </div>
      </div>
      <div id='css'></div>
      <div id='webgl'></div>
    </div>
  );
}

export default App;
