import * as THREE from 'three'
import * as TWEEN from 'tween'
import * as csss3d from '../node_modules/three/examples/jsm/renderers/CSS3DRenderer'
import { OrbitControls } from '../node_modules/three/examples/jsm/controls/OrbitControls'


var camera, controls, root, scene, renderer, background, renderer2 ,  workPagePositionX;
controls = { update: () => { } }
// var mouseX = 0, mouseY = 0;
// var sphere;
let rotation = true
var light
// var lightMovementAmplitude = 200
export function spin() {
    // animate this rotation
    // root.rotation.y += 90
    rotation = !rotation
}
// let homeZ = 800
const getRadians = degrees => Math.PI / 180 * degrees

function animate() {
    document.getElementsByClassName('App')[0].style.backgroundColor =  `#${Math.floor(Math.random()*16777215).toString(16)}`;

    // windowHalfX = window.innerWidth / 2;
    // windowHalfY = window.innerHeight / 2;

    camera = new THREE.PerspectiveCamera(
        45, window.innerWidth / window.innerHeight, 0.1, 4000
    );
    camera.position.set(0, 0, 800);

    scene = new THREE.Scene()
    root = new THREE.Object3D()
    root.position.y = 5
    root.position.x = -2
    // root.rotateY(getRadians(90))
    scene.add(root)

    background = makeElementObject('main')
    background.rotation.y = getRadians(90)
    root.add(background);

    if(window.innerWidth < 800){
    //     workPagePositionX = 600
    //     workView = makeElementObject('WorkPage')
    //     workView.position.x = 1000
    //     root.add(workView)
    }else{
    //     workPagePositionX = 1200
    camera.position.z += 100

    }

    // const works = makeElementObject('WorksMenuPage')
    // works.position.x = workPagePositionX
    // // works.rotation.y = getRadians(180)
    // root.add(works)

    // light
    // var ambientLight = new THREE.AmbientLight( 0x353535 );
    // root.add( ambientLight );

    light = new THREE.PointLight(0xffffff, 1, 0);
    light.castShadow = true;
    light.position.z = 150;
    light.shadow.mapSize.width = 256;  // default
    light.shadow.mapSize.height = 256; // default
    light.shadow.camera.near = 1;       // default
    light.shadow.camera.far = 2000;      // default
    light.shadow.bias = -0.001;

    // scene.add( new THREE.PointLightHelper( light, 10 ) )

    // root.add( light );

    renderer2 = new csss3d.CSS3DRenderer();
    renderer2.setSize(window.innerWidth, window.innerHeight);
    renderer2.domElement.style.position = 'absolute';
    renderer2.domElement.style.top = 0;
    document.querySelector('#css').appendChild(renderer2.domElement);

    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    document.querySelector('#webgl').appendChild(renderer.domElement);
    render(renderer2, renderer, camera, scene)

    controls = new OrbitControls(camera, renderer2.domElement);

}

function makeElementObject(elTitle) {
    const obj = new THREE.Object3D()

    // const element = document.createElement( tye );
    let element = document.getElementsByClassName(elTitle)[0]
    element.style.width = window.innerWidth//width+'px';
    element.style.height = window.innerHeight//height+'px';
    element.style.opacity = 0.999;

    var css3dObject = new csss3d.CSS3DObject(element);
    obj.css3dObject = css3dObject
    obj.add(css3dObject)
    // obj.color = new THREE.Color('black')

    // make an invisible plane for the DOM element to chop
    // clip a WebGL geometry with it.

    
    var material = new THREE.MeshBasicMaterial({
        opacity: 0,
        color: new THREE.Color(0x111111), // shade of black
        blending: THREE.NoBlending,
        side: THREE.DoubleSide,
    });
    var geometry = new THREE.BoxGeometry(window.innerWidth, window.innerHeight, 25);
    var mesh = new THREE.Mesh(geometry, material);
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    mesh.position.z = -50
    obj.lightShadowMesh = mesh
    obj.add(mesh);

    return obj
}

function render(cssRenderer, renderer, camera, scene) {
    scene.updateMatrixWorld()
    controls.update();

    renderer.render(scene, camera)
    cssRenderer.render(scene, camera)

    // if(rotation) root.rotation.y += 0.01
    TWEEN.update()
    requestAnimationFrame(() => { render(cssRenderer, renderer, camera, scene) })
}

export const spinBack = () => {
    let rotation = { y: background.rotation._y }
    new TWEEN.Tween(rotation)
        .to({ y: getRadians(0) }, 2000)
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
            background.rotation.y = rotation.y 
        })
        .start()
}

export const spinToWorks = async () => {

    let rotation = { y: background.rotation.y }
    new TWEEN.Tween(rotation)
        .to({ y: getRadians(180) }, 2000)
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
            background.rotation.y = rotation.y 
        })
        .start()
    let zoom = { z: camera.position.z, x: 0 }
    new TWEEN.Tween(zoom)
        .to({ z: 900 }, 3000)
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
            camera.position.z = zoom.z
        })
        .start()
    await sleep(3000)
    let pan = { x: camera.position.x }
    new TWEEN.Tween(pan)
        .to({ x: workPagePositionX }, 6000)
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
            camera.position.x = pan.x
            controls.target.set(pan.x, 0, 0)
        })
        .start()

}

const sleep = (time) => new Promise(resolve => setTimeout(resolve, time));

export default animate